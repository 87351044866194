import { useState } from 'react';
import CardFull from 'src/components/cards/card-full';
import CardH from 'src/components/cards/card-h';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';
const SectionFullHWtab = ({ data, headingColor = '', showHeading = true, eventDataLayer }) => {
  const [dataItem, setDataItem] = useState(data[0]);
  const [active, setActive] = useState({
    key: 0,
    status: true
  });

  const heading = dataItem?.heading;
  const cloneData = [...dataItem?.data];
  const firstData = cloneData.shift();
  const secondBlock = cloneData.slice(0, 4);

  return (
    <div className='section-full-tab'>
      {showHeading && (
        <div className={`section-title ${headingColor}`}>
          <HeadingTag
            type={KEY_HEADING_TAG.SECTION_SECTION}
            path={dataItem.link}
            heading={heading}
          />
          {Array.isArray(data) && data.length > 1 && (
            <div className='btn-title-tab'>
              {data.map(
                (item, index) =>
                  item.data.length > 0 && (
                    <a
                      key={index}
                      className={`${
                        active.status && active.key === index ? 'current' : ''
                      }`}
                      onClick={() => {
                        setDataItem(data[index]);
                        setActive({ key: index, status: true });
                      }}
                    >
                      {item.heading}
                    </a>
                  )
              )}
            </div>
          )}
        </div>
      )}
      <div className={`row ${data.length > 1 ? 'tab-body' : ''}`}>
        {dataItem.data.length > 0 && (
          <>
            <div className='col-lg-7 mb-4 mb-md-0 z-index col-md-6 col-sm-12'>
              <CardFull
                showHeading={showHeading}
                showHeadingTag={false}
                data={firstData}
                path={firstData?.link}
                heightImg='--height400'
                //! DATALAYER
                 eventDataLayer={{
                   ...eventDataLayer,
                   data: {
                     ...eventDataLayer?.data,
                     title: firstData?.title,
                     heading: heading
                   }
                 }}
              />
            </div>
            <div className='col-lg-5 col-md-6  col-sm-12'>
              {secondBlock.map((item, index) => (
                <div key={index}>
                  <CardH
                    path={item.link}
                    data={item}
                    width={true}
                    showBlurb={false}
                    //! DATALAYER
                    eventDataLayer={{
                      ...eventDataLayer,
                      data: {
                        ...eventDataLayer?.data,
                        title: item?.title,
                        index: index + 2,
                        heading: heading
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SectionFullHWtab;
