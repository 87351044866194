import { useEffect, useState } from 'react';
import { FaCalendarAlt, FaRegEye } from 'react-icons/fa';
import Image, { KEY_IMAGE } from 'src/components/image';
import { incrementCountview } from 'src/utils/increment-countview';
import { useDateUpdate, usePageViews } from 'src/utils/helper';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { NEXT_DEFAULT_COUNT_VIEW } from 'src/constants';
const CardH = ({
  data,
  path,
  FullH = false,
  width = false,
  textColor = '',
  showBlurb = true,
  external = false,
  BreakingNews = false,
  eventDataLayer
}) => {
  if (!data) return null;
  const [countView, setCountView] = useState(0);

  useEffect(async () => {
    await incrementCountview(data)
      .then((item) => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const dateUpdate = useDateUpdate(data?.published_at);

  return (
    <>
      <a
        tabIndex='-1'
        href={path}
        target={external ? '_blank' : '_self'}
        rel={external ? 'noopener noreferrer' : ''}
        //! DATALAYER
        onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
      >
        <div className='card-wrapper'>
          <div className={`card-h ${textColor}`}>
            <div className='row'>
              <div
                className={`${!BreakingNews ? '' : 'custom-padding'} ${
                  width && `${FullH ? 'col-lg-5' : 'col-lg-4'}`
                } col-md-5 col-5 img-scale-animate`}
              >
                <Image
                  image={data?.image}
                  title={data?.title}
                  external={external}
                  type={KEY_IMAGE.CARD_MEDIUM}
                />
              </div>
              <div
                className={`col-md-7 col-7 ${
                  width && `${FullH ? 'col-lg-7' : 'col-lg-8'}`
                }`}
              >
                {
                  <div
                    className={
                      textColor === '' ? 'post-date-dark' : 'post-date-light'
                    }
                  >
                    <ul className={`${BreakingNews && 'custom-font'}`}>
                      <li>
                        <FaCalendarAlt />
                        <span className={`${BreakingNews && 'custom-font'}`}>
                          {dateUpdate}
                        </span>
                      </li>
                      {countView > NEXT_DEFAULT_COUNT_VIEW && (
                        <li>
                          <FaRegEye />
                          <span>{usePageViews(countView)}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                }
                <h3 className={`m-0 ${BreakingNews && 'custom-font'}`}>
                  {data?.title}
                </h3>
                {showBlurb && <p className='text-trim-2'>{data?.showBlurb}</p>}
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

export default CardH;
