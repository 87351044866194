export const KEY_HEADING_TAG = {
  DEFAULT: 'default',
  ONBACKGROUND: 'onbackgroud',
  SECTION_SECTION: 'section-section',
  DETAIL: 'detail',
  LOTTERY: 'lottery',
  CARD: 'card'
};

const HeadingTag = ({
  type,
  title,
  path,
  heading,
  noneBg = true
}) => {
  const defaultDom = <>
    <div className='line-one' />
    <div className='line-two' />
    <div className='line-three' />
    </>;

  switch (type) {
    case KEY_HEADING_TAG.DEFAULT:
      return (
        <>
        {defaultDom}
        {path
          ? <a href={path}>
              <div className='tag-box'>
                <h2>{heading}</h2>
              </div>
            </a>
          : <div className='tag-box'><h2>{heading}</h2></div>}
      </>
      );
    case KEY_HEADING_TAG.DETAIL:
      return (
        <>
        {defaultDom}
        <h2 className='tag-box'>{heading || title}</h2>
      </>
      );
    case KEY_HEADING_TAG.LOTTERY:
      return (
        <>
        {defaultDom}
        <h2 className='tag-box'>
          <a href={path}>{heading}</a>
        </h2>
      </>
      );
    case KEY_HEADING_TAG.SECTION_SECTION:
      return (
        <>
        {defaultDom}
        {path
          ? <a href={path} title={heading} >
              <div className='tag-box'>
                <h2>{heading}</h2>
              </div>
            </a>
          : <div className='tag-box'>{heading}</div>
        }
      </>
      );
    case KEY_HEADING_TAG.ONBACKGROUND:
      return (
        <>
        {path
          ? <a href={path} title={heading}>
              <div className={`tag-box ${!noneBg ? 'unset' : ''}`}>
                <h2>
                  {heading}
                </h2>
              </div>
            </a>
          : <div className='tag-box'><h2> {heading} </h2></div>}
      </>
      );
    default: return null;
  }
};

export default HeadingTag;
